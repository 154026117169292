<template>
  <div>
    <h1 class="auth-layout__title">{{ $t('login.title') }}</h1>
    <a-form
        :form="form"
        id="formLogin"
        class="auth-layout__form"
        @submit="handleSubmit"
    >
      <a-form-item label="E-mail" v-if="!twoFactorCode">
        <a-input
            size="large"
            type="email"
            :class="{'input-err': isLoginError}"
            @keyup="keyupInput('email')"
            :placeholder="$t('login.enter_email')"
            v-decorator="[
                'email',
                {rules: [
                    {type: 'email', message: $t('messages.emailNotValid')},
                    {required: true, message: $t('messages.requiredEmail')}
                ]}
              ]"
        >
        </a-input>
      </a-form-item>
      <a-form-item :label="$t('login.password')"  v-if="!twoFactorCode">
        <a-input-password
            allow-clear
            size="large"
            type="password"
            @change="keyupInput('password')"
            autocomplete="false"
            :placeholder="$t('login.enter_password')"
            v-decorator="[
                'password',
                {rules: [
                    { required: true, message: $t('messages.passwordRequired') }]}
            ]"
        >
        </a-input-password>
        <div v-if="isLoginError && !isLoginLimitError" class="custom-error">{{ $t('messages.wrongCredentials') }}</div>
        <div v-if="isLoginError && isLoginLimitError" class="custom-error">{{ $t('messages.wrongLimitAttempts') }}</div>
      </a-form-item>

      <!-- Two-factor start-->
      <a-form-item :label="$t('login.two_factor_code')"  v-if="twoFactorCode">
        <a-input
          allow-clear
          size="large"
          type="number"
          max="9999999"
          :class="{'input-err': isLoginError}"
          @change="keyupInput('two_factor_code')"
          autocomplete="false"
          :placeholder="$t('login.enter_code')"
          v-decorator="[
           'two_factor_code',
            {rules: [
              { required: true, message: $t('messages.codeRequired') }]}
          ]"
        >
        </a-input>

        <div v-if="isLoginError && !twoFactorCode" class="custom-error">{{ $t('messages.codeRequired') }}</div>
        <div v-if="isLoginError && twoFactorCode && validateErrors.two_factor_code" class="custom-error">{{ validateErrors.two_factor_code }}</div>
        </a-form-item>
        <!-- Two-factor end -->
      <div class="auth-layout__action">
        <a-button
            type="primary"
            html-type="submit"
            :loading="loginBtn"
            :disabled="loginBtn || hasErrors(form.getFieldsError()) || isLoginError">
          {{ $t('login.title') }}
        </a-button>
        <router-link to="/user/password-recovery" class="user-forgot-pass">
          {{ $t('login.forgot_password') }}
        </router-link>
      </div>
      <a-radio-group :defaultValue="defaultLocale" class="language-switcher" @change="switchLanguage">
        <a-radio-button value="en">
          English
        </a-radio-button>
        <a-radio-button value="fr">
          French
        </a-radio-button>
      </a-radio-group>
    </a-form>
  </div>
</template>

<script>
import { hasErrors } from '@/utils/helpers/checkFormErrors';
import clearIcon from '@/assets/icons/close-icon.svg'
import moment from "moment";

export default {
  name: 'Login',
  data() {
    return {
      hasErrors,
      clearIcon,
      isLoginError: false,
      isLoginLimitError: false,
      loginBtn: false,
      twoFactorCode: false,
      twoFactorHash: '',
      defaultLocale: localStorage.locale ? localStorage.locale : "en",
      validateErrors: {},
      timeSend: 0,
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'login' });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.timeSend = moment().toISOString();

      //console.log(this.timeSend)
      this.loginBtn = true
      this.form.validateFields((err, values) => {
        if (!err) {
          const loginParams = { ...values }
          if (this.twoFactorCode) {
              loginParams.hash = this.twoFactorHash
          }
          this.$store.dispatch('Login', loginParams)
            .then(() => this.loginSuccess())
            .catch((e) => this.requestFailed(e))
            .finally(() => this.loginBtn = false)
        } else {
          this.isLoginError = true;
          this.cleanLimit();
          setTimeout(() => {
            this.loginBtn = false
          }, 6000)
        }
      });
    },
    keyupInput(field) {
      if (field === 'email') {
        this.isLoginError = !!this.form.getFieldError(field);
      } else if (field === 'two_factor_code') {
        this.isLoginError = !!this.form.getFieldError(field);
      }
      this.cleanLimit();
    },
    loginSuccess () {
      this.$router.go('/');
      this.cleanLoginError();
      this.cleanLimit();
    },
    cleanLimit() {
      this.isLoginLimitError = false
    },
    cleanLoginError() {
      this.isLoginError = false
    },
    cleanTwoFactorCode() {
      this.twoFactorCode = false
    },
    cleanAll() {
        this.cleanLimit();
        this.cleanLoginError();
        this.cleanTwoFactorCode();
    },
    requestFailed (e = null) {
      this.validateErrors = {};
      this.isLoginError = true
      this.cleanLimit();
      if (e) {
        if (e.response.status === 429) {
          this.isLoginLimitError = true;
          setTimeout(() => {
              this.cleanLoginError();
              this.cleanLimit();
          },60000);
        } else if (e.response.status === 449) {
          this.twoFactorHash = e.response.data.error.hash
          this.cleanAll();
          this.twoFactorCode = true;
          setTimeout(() => {
            this.cleanAll();
          },480000);
        } else if (e.response.status === 422) {
            try {
                Object.entries(e.response.data.error.errors).forEach(([key, value]) => {
                    this.validateErrors[key] = value.join("\n")
                });
            } catch (error) {}
            setTimeout(() => {
                if (this.timeSend < moment().subtract(5, 'seconds').toISOString()) {
                    this.cleanLoginError();
                    this.cleanLimit();
                }
            },5500);
        }
      }
    },
    switchLanguage(e) {
      localStorage.setItem("locale", e.target.value);
      this.$i18n.locale = e.target.value;
      // this.form.resetFields();
    }
  },
}
</script>

<style lang="less">
.content-auth {
  .ant-input-clear-icon {
    background-image: url('~@/assets/icons/close-icon.svg');
    background-repeat: no-repeat;
    height: 22px;
    width: 22px;
    margin-right: 5px;

    svg {
      display: none;
    }
  }
}
.language-switcher {
  .ant-radio-button-wrapper {
    &.ant-radio-button-wrapper-checked {
      border-color: #F6CE54;
      box-shadow: -1px 0 0 0 #F6CE54;
    }
  }
}
</style>

<style lang="less" scoped>
  .input-err {
    border: 1px solid #FF5D53;
  }

  .user-forgot-pass {
    color: #40454E;
    font-size: 11px;
  }
  .language-switcher {
    margin-top: 30px;
  }
</style>
