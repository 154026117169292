<template>
  <div v-if="!resetPassword">
    <h1 class="auth-layout__title">{{ $t('login.create_new_password') }}</h1>
    <a-form
        :form="form"
        id="formPasswordRecovery"
        class="auth-layout__form"
        @submit="handleSubmit"
    >
      <a-form-item :label="$t('login.password')">
        <a-input-password
            size="large"
            type="password"
            @change="keyupInput"
            :placeholder="$t('login.enter_password')"
            v-decorator="[
                'password',
                {rules: [
                    {required: true, message: $t('messages.passwordRequired')},
                    {validator: validateToNextPassword},
                    {pattern: new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{5,15}$'), message: $t('messages.passwordRegExp')}]},
            ]"
        >
        </a-input-password>
      </a-form-item>
      <a-form-item :label="$t('login.confirm_password')">
        <a-input-password
            size="large"
            type="password"
            @change="keyupInput"
            :placeholder="$t('login.confirm_password_placeholder')"
            v-decorator="[
                'password_confirmation',
                {rules: [
                    {required: true, message: $t('messages.passwordConfirm')},
                    {validator: compareToFirstPassword}]},
            ]"
        >
        </a-input-password>
        <div
          v-if="isRequestError && (errors.length === 0 || errors.password_confirmation || errors.password )"
          class="custom-error"
        >
          {{ errors.password ? errors.password : $t('messages.passwordIsNotValid') }}
        </div>
      </a-form-item>
      <div class="auth-layout__action">
         <a-button
             type="primary"
             html-type="submit"
             :loading="resetButton"
             :disabled="resetButton || hasErrors(form.getFieldsError()) || isRequestError">
          {{ $t('login.set_password') }}
        </a-button>
      </div>
    </a-form>
  </div>
  <div v-else>
    <h1 class="auth-layout__title">{{ $t('login.password_has_been_set') }}</h1>
    <div v-if="isMobile" class="auth-layout__text">{{ $t('login.mobile_text') }}</div>
    <div v-if="!isMobile" class="auth-layout__text">{{ $t('login.can_log_in') }}</div>
    <a-button
        v-if="!isMobile"
        type="primary"
        @click="returnToLogin">
      {{ $t('login.title') }}
    </a-button>
  </div>
</template>

<script>
import { hasErrors } from '@/utils/helpers/checkFormErrors';
import {mapMutations} from 'vuex';
import { passwordRegExp, passwordRequired, passwordConfirm, passwordIsNotValid } from "@/utils/messages";

export default {
  name: 'ResetPassword',
  data() {
    return {
      hasErrors,
      passwordRegExp,
      passwordRequired,
      passwordConfirm,
      passwordIsNotValid,
      confirmDirty: false,
      resetButton: false,
      isRequestError: false,
      isMobile: false,
      errors: {}
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'recoveryPassword' });
  },
  mounted() {
    this.SET_BG_IMAGE_PATH("/auth_images/ResetPassword.png");
    this.$route.query.mobile == "1" ? this.isMobile = true : null;
  },
  computed: {
    resetPassword() {
      return this.$store.getters['getResetPasswordState'];
    },
  },
  methods: {
    ...mapMutations(['SET_BG_IMAGE_PATH']),
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue('password')) {
        callback(this.$t('login.two_passwords_error'));
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(['password_confirmation'], { force: true });
      }
      callback();
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let newPassword = {
            token: this.$route.query.token,
            password: values.password,
            password_confirmation: values.password_confirmation
          }
          this.$store.dispatch('ResetPassword', newPassword)
            .then(()=> this.resetButton = false)
            .catch((e) => {
              this.errors = {};
              this.isRequestError = true;
              if (e) {
                if (e.response.status === 422) {
                  try {
                    Object.entries(e.response.data.error.errors).forEach(([key, value]) => {
                      if(values[key]) {
                        this.errors[key] = value.join("\n")
                      }
                    });
                  } catch (error) {}
                  setTimeout(() => {
                    this.isRequestError = false;
                    this.changeButton = false;
                  },10000);
                }
              }
            }).finally(() => {
              this.resetButton = false
            });
        }
      });
    },
    keyupInput() {
      this.resetButton = false
      this.isRequestError = false
    },
    returnToLogin() {
      this.$store.dispatch('ReturnToLogin')
      this.$router.push({ path: '/user/login' })
    }
  },
};
</script>

<style lang="less" scoped></style>
