var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"auth-layout__title"},[_vm._v(_vm._s(_vm.$t('login.title')))]),_c('a-form',{staticClass:"auth-layout__form",attrs:{"form":_vm.form,"id":"formLogin"},on:{"submit":_vm.handleSubmit}},[(!_vm.twoFactorCode)?_c('a-form-item',{attrs:{"label":"E-mail"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {rules: [
                  {type: 'email', message: _vm.$t('messages.emailNotValid')},
                  {required: true, message: _vm.$t('messages.requiredEmail')}
              ]}
            ]),expression:"[\n              'email',\n              {rules: [\n                  {type: 'email', message: $t('messages.emailNotValid')},\n                  {required: true, message: $t('messages.requiredEmail')}\n              ]}\n            ]"}],class:{'input-err': _vm.isLoginError},attrs:{"size":"large","type":"email","placeholder":_vm.$t('login.enter_email')},on:{"keyup":function($event){return _vm.keyupInput('email')}}})],1):_vm._e(),(!_vm.twoFactorCode)?_c('a-form-item',{attrs:{"label":_vm.$t('login.password')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {rules: [
                  { required: true, message: _vm.$t('messages.passwordRequired') }]}
          ]),expression:"[\n              'password',\n              {rules: [\n                  { required: true, message: $t('messages.passwordRequired') }]}\n          ]"}],attrs:{"allow-clear":"","size":"large","type":"password","autocomplete":"false","placeholder":_vm.$t('login.enter_password')},on:{"change":function($event){return _vm.keyupInput('password')}}}),(_vm.isLoginError && !_vm.isLoginLimitError)?_c('div',{staticClass:"custom-error"},[_vm._v(_vm._s(_vm.$t('messages.wrongCredentials')))]):_vm._e(),(_vm.isLoginError && _vm.isLoginLimitError)?_c('div',{staticClass:"custom-error"},[_vm._v(_vm._s(_vm.$t('messages.wrongLimitAttempts')))]):_vm._e()],1):_vm._e(),(_vm.twoFactorCode)?_c('a-form-item',{attrs:{"label":_vm.$t('login.two_factor_code')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
         'two_factor_code',
          {rules: [
            { required: true, message: _vm.$t('messages.codeRequired') }]}
        ]),expression:"[\n         'two_factor_code',\n          {rules: [\n            { required: true, message: $t('messages.codeRequired') }]}\n        ]"}],class:{'input-err': _vm.isLoginError},attrs:{"allow-clear":"","size":"large","type":"number","max":"9999999","autocomplete":"false","placeholder":_vm.$t('login.enter_code')},on:{"change":function($event){return _vm.keyupInput('two_factor_code')}}}),(_vm.isLoginError && !_vm.twoFactorCode)?_c('div',{staticClass:"custom-error"},[_vm._v(_vm._s(_vm.$t('messages.codeRequired')))]):_vm._e(),(_vm.isLoginError && _vm.twoFactorCode && _vm.validateErrors.two_factor_code)?_c('div',{staticClass:"custom-error"},[_vm._v(_vm._s(_vm.validateErrors.two_factor_code))]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"auth-layout__action"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loginBtn,"disabled":_vm.loginBtn || _vm.hasErrors(_vm.form.getFieldsError()) || _vm.isLoginError}},[_vm._v(" "+_vm._s(_vm.$t('login.title'))+" ")]),_c('router-link',{staticClass:"user-forgot-pass",attrs:{"to":"/user/password-recovery"}},[_vm._v(" "+_vm._s(_vm.$t('login.forgot_password'))+" ")])],1),_c('a-radio-group',{staticClass:"language-switcher",attrs:{"defaultValue":_vm.defaultLocale},on:{"change":_vm.switchLanguage}},[_c('a-radio-button',{attrs:{"value":"en"}},[_vm._v(" English ")]),_c('a-radio-button',{attrs:{"value":"fr"}},[_vm._v(" French ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }