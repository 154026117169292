<template>
  <main-modal
      :active="modalActive"
      @onClose="onClose">
    <template v-slot:modalHeader>
      <a-button
          v-if="currentStep > 0 && currentStep < 4"
          @click="prevStep()"
          class="back-btn button-without-animation">
        <i class="anticon"><arrow/></i>
        Back</a-button>
    </template>
    <template v-slot:modalBody>
      <div style="height: 100%">
        <create-organization-manually @closeModal="onClose"/>
      </div>
    </template>
  </main-modal>
</template>

<script>
import MainModal from '@/components/MainModal/MainModal'
import CreateOrganizationManually from '@/views/admin/CreateOrganizationManually/CreateOrganizationManually'
import arrow from '@/assets/icons/back.svg?inline'

export default {
  name: 'CreateOrgaizationUser',
  props: ['active', 'headerBorder'],
  data(){
    return {
      step: null,
      modalActive: true
    }
  },
  components: {
    arrow,
    MainModal,
    CreateOrganizationManually
  },
  computed: {
    currentStep() {
      return this.$store.getters['createOrganization/getCurrentStep']
    },
    getRole() {
      return parseInt(this.$store.getters['getRole'])
    }
  },
  methods: {
    defaultModalState() {
      this.$store.commit('createOrganization/SET_CURRENT_STEP', 0)
    },
    prevStep () {
      this.step = this.currentStep
      if (this.currentStep == 2 && this.getRole !== 1) {
        this.$store.commit('createOrganization/SET_CURRENT_STEP', this.step -= 2)
      } else {
        this.$store.commit('createOrganization/SET_CURRENT_STEP', this.step -= 1)
      }
    },
    onClose() {
      this.$emit('closeModal', false)
      this.modalActive = false;
      this.defaultModalState()
    }
  }
}
</script>

<style lang="less">
  .create-organization {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: -20px;

    button {
      margin-bottom: 40px;
    }
  }
</style>


