var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.resetPassword)?_c('div',[_c('h1',{staticClass:"auth-layout__title"},[_vm._v(_vm._s(_vm.$t('login.create_new_password')))]),_c('a-form',{staticClass:"auth-layout__form",attrs:{"form":_vm.form,"id":"formPasswordRecovery"},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":_vm.$t('login.password')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {rules: [
                  {required: true, message: _vm.$t('messages.passwordRequired')},
                  {validator: _vm.validateToNextPassword},
                  {pattern: new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{5,15}$'), message: _vm.$t('messages.passwordRegExp')}]} ]),expression:"[\n              'password',\n              {rules: [\n                  {required: true, message: $t('messages.passwordRequired')},\n                  {validator: validateToNextPassword},\n                  {pattern: new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{5,15}$'), message: $t('messages.passwordRegExp')}]},\n          ]"}],attrs:{"size":"large","type":"password","placeholder":_vm.$t('login.enter_password')},on:{"change":_vm.keyupInput}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('login.confirm_password')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password_confirmation',
              {rules: [
                  {required: true, message: _vm.$t('messages.passwordConfirm')},
                  {validator: _vm.compareToFirstPassword}]} ]),expression:"[\n              'password_confirmation',\n              {rules: [\n                  {required: true, message: $t('messages.passwordConfirm')},\n                  {validator: compareToFirstPassword}]},\n          ]"}],attrs:{"size":"large","type":"password","placeholder":_vm.$t('login.confirm_password_placeholder')},on:{"change":_vm.keyupInput}}),(_vm.isRequestError && (_vm.errors.length === 0 || _vm.errors.password_confirmation || _vm.errors.password ))?_c('div',{staticClass:"custom-error"},[_vm._v(" "+_vm._s(_vm.errors.password ? _vm.errors.password : _vm.$t('messages.passwordIsNotValid'))+" ")]):_vm._e()],1),_c('div',{staticClass:"auth-layout__action"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.resetButton,"disabled":_vm.resetButton || _vm.hasErrors(_vm.form.getFieldsError()) || _vm.isRequestError}},[_vm._v(" "+_vm._s(_vm.$t('login.set_password'))+" ")])],1)],1)],1):_c('div',[_c('h1',{staticClass:"auth-layout__title"},[_vm._v(_vm._s(_vm.$t('login.password_has_been_set')))]),(_vm.isMobile)?_c('div',{staticClass:"auth-layout__text"},[_vm._v(_vm._s(_vm.$t('login.mobile_text')))]):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"auth-layout__text"},[_vm._v(_vm._s(_vm.$t('login.can_log_in')))]):_vm._e(),(!_vm.isMobile)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.returnToLogin}},[_vm._v(" "+_vm._s(_vm.$t('login.title'))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }