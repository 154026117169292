<template>
    <div v-if="!emailSent">
      <h1 class="auth-layout__title">{{ $t('login.password_recovery') }}</h1>
      <a-form
          :form="form"
          id="formPasswordForgot"
          class="auth-layout__form"
          @submit="handleSubmit"
      >
        <a-form-item label="Email">
          <a-input
              size="large"
              type="email"
              @change="keyupInput"
              :placeholder="$t('login.enter_email')"
              v-decorator="[
                'email',
                {rules: [
                    {type: 'email', message: $t('messages.emailNotValid')},
                    {required: true, message: $t('messages.requiredEmail')}]
                }
              ]"
          >
          </a-input>
          <div v-if="isRequestError" class="custom-error">{{ $t('messages.emailDoesntExist') }}</div>
        </a-form-item>
        <div class="auth-layout__action">
          <a-button
            type="primary"
            html-type="submit"
            :loading="acceptButton"
            :disabled="acceptButton || hasErrors(form.getFieldsError()) || isRequestError">
          {{ $t('login.send_request') }}
        </a-button>
        </div>
      </a-form>
    </div>
    <div v-else>
      <h1 class="auth-layout__title">{{ $t('login.request_was_sent') }}</h1>
      <div class="auth-layout__text">{{ $t('login.check_email') }}</div>
      <a-button
          type="primary"
          @click="returnToLogin">
        Ok
      </a-button>
    </div>
</template>

<script>
import { hasErrors } from '@/utils/helpers/checkFormErrors';
import {mapMutations} from 'vuex';

export default {
  name: "ForgotPassword",
  data() {
    return {
      hasErrors,
      isRequestError: false,
      acceptButton: false
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'forgotPassword' });
  },
  computed: {
    emailSent() {
      return this.$store.getters['getEmailSentState'];
    },
  },
  mounted() {
    this.SET_BG_IMAGE_PATH("/auth_images/ResetPassword.png");
  },
  beforeRouteLeave(to, from, next) {
    this.form.resetFields()
    next()
  },
  methods: {
    ...mapMutations(['SET_BG_IMAGE_PATH']),
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('RecoverPassword', {email: values.email})
              .then(()=> this.acceptButton = false)
              .catch(() => this.isRequestError = true)
              .finally(() => {
                this.acceptButton = false
              });
        }
      });
    },
    keyupInput() {
      this.isRequestError = false
    },
    returnToLogin() {
      this.$store.dispatch('ReturnToLogin')
    }
  },
};
</script>
